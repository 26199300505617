<template >
    <div class="py-5 sm:py-5 z-10">
        <div class="mx-auto max-w-2xl px-6 lg:px-8">

            <div class="rounded-3xl ring-1 ring-transparent sm:mt-10 shadow-2xl"
                :class="inverted == 'false' ? 'bg-white' : 'bg-gray-500'">

                <div class="-mt-2 p-2 lg:mt-0 lg:w-full  lg:flex-shrink-0">
                    <div class="p-8 sm:p-10 lg:flex-auto">
                        <h2 class="text-2xl font-bold tracking-tight text-mobevpop-600 ">{{ title }}</h2>
                        <p class="mt-4 text-base leading-7 text-gray-600"
                            :class="inverted == 'true' ? 'text-white' : 'text-gray-600'" v-html="description"></p>
                        <div class="mt-5 flex items-center gap-x-4" v-if="included1">
                            <h4 class="flex-none text-sm font-semibold leading-6 text-mobevpop-600">O que está incluido</h4>
                            <div class="h-px flex-auto bg-gray-100"></div>
                        </div>
                        <ul role="list"
                            class="mt-3 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                            <li class="flex gap-x-3" v-if="included1">
                                <svg class="h-6 w-5 flex-none text-mobevpop-600" viewBox="0 0 20 20" fill="currentColor"
                                    aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                        clip-rule="evenodd" />
                                </svg>
                                {{ included1 }}
                            </li>
                            <li class="flex gap-x-3" v-if="included2">
                                <svg class="h-6 w-5 flex-none text-mobevpop-600" viewBox="0 0 20 20" fill="currentColor"
                                    aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                        clip-rule="evenodd" />
                                </svg>
                                {{ included2 }}
                            </li>
                            <li class="flex gap-x-3" v-if="included3">
                                <svg class="h-6 w-5 flex-none text-mobevpop-600" viewBox="0 0 20 20" fill="currentColor"
                                    aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                        clip-rule="evenodd" />
                                </svg>
                                {{ included3 }}
                            </li>
                            <li class="flex gap-x-3" v-if="included4">
                                <svg class="h-6 w-5 flex-none text-mobevpop-600" viewBox="0 0 20 20" fill="currentColor"
                                    aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                        clip-rule="evenodd" />
                                </svg>
                                {{ included4 }}
                            </li>
                        </ul>
                        <div v-if="!price">
                            <p class="mt-6 flex items-baseline justify-center gap-x-2">
                                <span class="text-3xl font-bold tracking-tight text-gray-400">Preço sob consulta</span>

                            </p>
                        </div>

                        <router-link :to="{ path: '/', hash: '#contactForm' }" type="button" v-if="!price"
                            @click="contactAction('Plano MOBEV BUSINESS')"
                            class="mt-10 block w-full rounded-md bg-mobevpop-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-mobevpop-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mobevpop-600"
                            data-te-ripple-init data-te-ripple-color="light">
                            Contactar
                        </router-link>

                    </div>

                    <div v-if="price"
                        class="my-auto rounded-2xl bg-gray-50  text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 ">
                        <div class="mx-auto max-w-xs px-8">
                            <div>
                                <p class="text-base font-semibold text-gray-600">A partir de </p>
                                <p class="mt-6 flex items-baseline justify-center gap-x-2">
                                    <span class="text-5xl font-bold tracking-tight text-gray-900">{{ price }}</span>
                                    <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">EUR</span>
                                </p>
                            </div>

                            <router-link :to="{ path: '/', hash: '#contactForm' }" type="button"
                                @click="contactAction('Plano MOBEV PRIVATE')"
                                class="mt-10 block w-full rounded-md bg-mobevpop-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-mobevpop-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mobevpop-600"
                                data-te-ripple-init data-te-ripple-color="light">
                                Contactar
                            </router-link>

                            <div>
                                <div class="disclaimer text-gray-400 mt-8">

                                    <p class=" py-2">*O valor apresentado tem como base o valor médio de instalações
                                        domésticas,
                                        podendo
                                        o mesmo sofrer
                                        alterações devido a: acessos, distância entre o carregador e o quadro principal,
                                        materiais a
                                        instalar,
                                        entre outros factores.</p>

                                    <p class="py-2">Não dispensa visita técnica.</p>

                                    <p class="py-2">Custo de visita técnica na zona da grande Lisboa: € 75,00 (em caso de
                                        adjudicação
                                        não será cobrado o
                                        custo da visita técnica)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.disclaimer {
    font-size: .6rem;
}
</style>

<script>
export default {
    emits: ["contact"],
    name: 'HelloWorld',
    props: {
        title: String,
        description: String,
        price: String,
        included1: String,
        included2: String,
        included3: String,
        included4: String,
        inverted: Boolean,
    },
    methods: {
        contactAction(value, event) {
            console.log('contactAction ' || event.target.value)
            this.$emit("contact", value);
        }
    },
}
</script>