<template>
    <!--<router-link to="/">home</router-link>-->
    <!-- Jumbotron -->
    <!--header-->
    <div class="main-header relative overflow-hidden  bg-cover bg-no-repeat p-12 text-center">
        <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
            style="background-color: rgba(0, 0, 0, 0.6)">
            <div class="flex h-full items-center justify-center">
                <div class="text-white">
                    <h2 class="mb-4 text-4xl font-semibold">
                        <img src="./assets/images/mobev_logo_white.png"
                            alt="Eletric car and eletric charging stations in a low light cenario">
                    </h2>
                    <router-link :to="{ path: '/', hash: '#contactForm' }" type="button"
                        class="rounded border-2 border-neutral-50 px-7 pb-[8px] pt-[10px] text-sm font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                        data-te-ripple-init data-te-ripple-color="light">
                        Contactar
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <!-- Jumbotron -->
    <!--MOBEV-->
    <div class="mx-auto sm:px-10 md:px-0 md:max-w-3xl lg:max-w-4xl py-10 sm:py-15">
        <h1 class="text-center text-3xl font-bold tracking-tight text-mobevpop-600 sm:text-4xl astro-font">Mobev</h1>
        <p class="p-4 mt-6 text-lg leading-8 text-gray-600">
            A MOBEV é uma empresa jovem, dinâmica, que conta com uma equipa de <strong>profissionais experientes</strong>,
            com <i>know-how</i> no desenvolvimento e instalação de infraestruturas para carregadores de veículos eléctricos
            - seja para uma empresa, para um cliente particular ou carregadores públicos.
            Acompanhamos a transição energética das frotas nas empresas e damos todo o apoio de consultadoria necessária
            para que a mudança seja feita com <strong>sustentabilidade e segurança</strong>. Somos conselheiros e parceiros
            de quem nos consulta e o nosso objectivo é trazer a solução mais adequada aos nossos clientes. Estudamos cada
            infraestrutura de forma individual e garantimos um orçamento rápido.
            <strong>Somos competitivos</strong> e as nossas soluções são aquelas de que precisa.
            Juntos vamos tornar <strong>o mundo mais sustentável</strong>!
        </p>
    </div>
    <!--MOBEVE DIFERENCE POINTS-->
    <div class="mx-auto sm:px-10 md:px-0 sm:max-w-3xl lg:max-w-4xl  py-10 sm:py-15 why-us">
        <h2 class=" text-center text-3xl font-bold tracking-tight text-mobevpop-600 sm:text-4xl astro-font">O que nos
            diferencia
        </h2>
        <div class="grid p-8 sm:p-0 sm:grid-cols-2 sm:gap-y-20 mt-5">

            <div class="p-4">
                <div class="grid grid-col-4 md:grid-cols-6">
                    <img src="./assets/images/back-in-time.png" alt="clock icon">
                    <h3 class="text-mobevpop-600 astro-font md:col-span-4">Poupe tempo</h3>
                </div>
                Ao solicitar uma proposta à MOBEV, saberá o quão rápido seremos a apresentar a solução mais adequada.
            </div>
            <div class="p-4 ">
                <div class="grid grid-col-4 md:grid-cols-6">
                    <img src="./assets/images/online-chat.png" alt="online chat icon">
                    <h3 class="text-mobevpop-600 astro-font md:col-span-4">Suporte</h3>
                </div>
                Ser cliente MOBEV é saber que tem um parceiro 24H por dia, 365 dias por ano.
            </div>
            <div class="p-4 ">
                <div class="grid grid-col-4 md:grid-cols-6">
                    <img src="./assets/images/snap.png" alt="clap fingers icon">
                    <h3 class="text-mobevpop-600 astro-font md:col-span-4">Facil de usar</h3>
                </div>
                Todo o processo é fácil, ágil e termina com a formação necessária para uma utilização sustentável,
                responsável e equilibrada.
            </div>
            <div class="p-4 ">
                <div class="grid grid-col-4 md:grid-cols-6">
                    <img src="./assets/images/dashboard.png" alt="analytics inside computer window icon">
                    <h3 class="text-mobevpop-600 astro-font md:col-span-4">Consultadoria</h3>
                </div>
                Somos o seu consultor para a transição energética da sua frota. Apresentamos soluções com base em consumos,
                fiscalidade e tendências de mercado. Aconselhamos e se assim entender, desenhamos a sua frota.
            </div>
        </div>
    </div>
    <!--PARTNERS-->
    <div class="partners bg-mobevpop-600">
        <div class=" mx-auto max-w-5xl  py-10 sm:py-15 mt-10">
            <h2 class=" text-center text-3xl font-bold tracking-tight text-mobevpop-500 sm:text-4xl astro-font">Parceiros
            </h2>

            <div class=" mx-auto max-w-5xl  ">
                <div class="grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 space-y-8">
                    <div class="place-self-center">
                        <img src="./assets/images/partners/Anecra.png" alt="Anecra logo">
                    </div>
                    <div class="place-self-center">
                        <img src="./assets/images/partners/imt_mobi.e.png" alt="Mobi.E logo">
                    </div>
                    <div class="place-self-center">
                        <img src="./assets/images/partners/araujogoncalves.png" alt="Araujo & Gonçalves logo">
                    </div>
                    <div class="place-self-center">
                        <img src="./assets/images/partners/redecor.png" alt="Redecor logo">
                    </div>
                    <div class="place-self-center">
                        <img src="./assets/images/partners/Tagus.png" alt="Tagus Park logo">
                    </div>
                    <div class="place-self-center">
                        <img src="./assets/images/partners/UVE.png" alt="UVE logo">
                    </div>
                    <div class="place-self-center">
                        <img src="./assets/images/partners/volte.png" alt="Volte logo">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--IMAGE-->
    <div class="relative invisible sm:visible z-0">
        <div class="image-alone ">
            <img src="./assets/images/charger.png" alt="electric car wall charging station sketch design">
        </div>
    </div>

    <!--PLANS-->
    <div class="mx-auto max-w-5xl  py-10 sm:py-15 mt-10 z-80">
        <h2 class=" text-center text-3xl font-bold tracking-tight text-mobevpop-600 sm:text-4xl astro-font">Planos</h2>

        <div class="grid grid-cols-1 md:grid-cols-2">
            <PriceCard title="MOBEV PRIVATE" description="Perfeito para garagens particulares e moradias." price="850"
                included1="Instalação" included2="Carregador" inverted=false @contact="contact" />
            <PriceCard title="MOBEV BUSINESS"
                description='<p class="py-3" >Conheça as nossas opções para a sua empresa. Carregador com controlo de acesso de carregamento, consumos, rede própria e gestão remota. Desenvolvimento e instalação de infraestruturas de carregamentos públicos.</p>
                <p class="py-3">Implementação de painéis fotovoltaicos para autoprodução de energia e integração com baterias.</p>
                <p class="py-3">Cada vez mais eficientes, as baterias para armazenamento de energia, para sua casa ou indústria, permitem fazer uma gestão energética com grandes benefícios financeiros e ambientais. As baterias permitem uma gestão de energia entre a produção dos PF, Vehicle To Grid (V2G) e a própria rede de distribuição.</p>'
                inverted=true @contact="contact" />
        </div>
    </div>


    <!--IMAGE-->
    <div class="relative invisible md:visible z-0">
        <div class="image-alone image-left">
            <img src="./assets/images/charger_station.png" alt="electric car charging station sketch design">
        </div>
    </div>


    <!-- CONTACT FORM -->
    <div class="mx-auto max-w-5xl py-10 sm:py-15" id="contactForm">
        <div class="grid grid-cols-1 md:grid-cols-1">
            <form class="flex w-full space-x-3" @submit.prevent="sendEmail" ref="contactForm">
                <div class="w-full max-w-1xl px-5 py-10 m-auto mt-10 bg-mobevpop-600 rounded-lg shadow">

                    <h2 class="text-center text-3xl font-bold tracking-tight text-white sm:text-4xl astro-font">
                        Contacte-nos</h2>
                    <div class="grid max-w-xl grid-cols-2 gap-4 m-auto mt-8">
                        <div class="col-span-2 lg:col-span-1">
                            <div class=" relative ">
                                <input type="text" id="contact-form-name"
                                    class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-mobevpop-500 focus:border-transparent"
                                    placeholder="Name" name="user_name" required />
                            </div>
                        </div>
                        <div class="col-span-2 lg:col-span-1">
                            <div class=" relative ">
                                <input type="text" id="contact-form-telefone"
                                    class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-mobevpop-500 focus:border-transparent"
                                    placeholder="Telefone" name="user_phone" required />
                            </div>
                        </div>
                        <div class="col-span-2 lg:col-span-2">
                            <div class=" relative ">
                                <input type="email" id="contact-form-email"
                                    class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-mobevpop-500 focus:border-transparent"
                                    placeholder="Email" name="user_email" required />
                            </div>
                        </div>
                        <div class="col-span-2">
                            <label class="text-gray-700" for="message">
                                <textarea
                                    class="flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-mobevpop-500 focus:border-transparent"
                                    id="message" placeholder="Escreva a sua mensagem" name="message" rows="5" cols="40"
                                    v-model="message" required>

                            </textarea>
                            </label>
                        </div>
                        <div class="col-span-2 text-right">
                            <button type="submit" value="Send"
                                class="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-mobevpop-600 focus:ring-offset-indigo-200 text-mobevpop-600 w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                Enviar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>



    <!--FOOTER-->
    <footer class="bg-gray-50  w-full py-8">

        <div class="max-w-screen-xl px-4 mx-auto">
            <div class="text-center">
                <img src="./assets/images/mobev_logo.png" alt="mobev logo" class="image-small" />
            </div>
            <div class="grid  md:grid-cols-4">
                <!--MORADA-->
                <div>
                    <h2 class="text-1xl font-bold tracking-tight text-mobevpop-600 sm:text-1xl astro-font">Morada</h2>
                    <a class="text-gray-400 dark:text-gray-300 dark:hover:text-white 
                    transition-colors duration-200">
                        <p>Avenida Jacques Delors,</p>
                        <p>Edificio Inovação II - nº 411 - 421 Taguspark</p>
                        <p>2740-122 - Porto Salvo</p>
                        <p>Oeiras</p>
                        <p>Portugal</p>
                        <p>© 2023 MOBEV</p>
                    </a>
                </div>

                <!--REDES SOCIAS-->
                <div class="col-span-2 mx-0 md:mx-auto ">
                    <div class="pt-8 flex sm:items-start md:items-center ">

                        <a href="https://web.whatsapp.com/send?phone=911162218" target="_blank"
                            class="text-gray-400 transition-colors duration-200 hover:text-mobevpop-600 dark:hover:text-white p-8">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                                class="text-xl transition-colors duration-200 hover:text-mobevpop-600 dark:hover:text-white"
                                viewBox="-2 -2 24.00 24.00">
                                <g transform="translate(-300.000000, -7599.000000)">
                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                        <path
                                            d="M259.821,7453.12124 C259.58,7453.80344 258.622,7454.36761 257.858,7454.53266 C257.335,7454.64369 256.653,7454.73172 254.355,7453.77943 C251.774,7452.71011 248.19,7448.90097 248.19,7446.36621 C248.19,7445.07582 248.934,7443.57337 250.235,7443.57337 C250.861,7443.57337 250.999,7443.58538 251.205,7444.07952 C251.446,7444.6617 252.034,7446.09613 252.104,7446.24317 C252.393,7446.84635 251.81,7447.19946 251.387,7447.72462 C251.252,7447.88266 251.099,7448.05372 251.27,7448.3478 C251.44,7448.63589 252.028,7449.59418 252.892,7450.36341 C254.008,7451.35771 254.913,7451.6748 255.237,7451.80984 C255.478,7451.90987 255.766,7451.88687 255.942,7451.69881 C256.165,7451.45774 256.442,7451.05762 256.724,7450.6635 C256.923,7450.38141 257.176,7450.3464 257.441,7450.44643 C257.62,7450.50845 259.895,7451.56477 259.991,7451.73382 C260.062,7451.85686 260.062,7452.43903 259.821,7453.12124 M254.002,7439 L253.997,7439 L253.997,7439 C248.484,7439 244,7443.48535 244,7449 C244,7451.18666 244.705,7453.21526 245.904,7454.86076 L244.658,7458.57687 L248.501,7457.3485 C250.082,7458.39482 251.969,7459 254.002,7459 C259.515,7459 264,7454.51465 264,7449 C264,7443.48535 259.515,7439 254.002,7439">
                                        </path>
                                    </g>
                                </g>
                            </svg>

                        </a>

                        <a href="https://www.instagram.com/mob_evolution/" target="_blank"
                            class="text-gray-400 transition-colors duration-200 hover:text-mobevpop-600 dark:hover:text-white p-8">
                            <svg width="40" height="40" fill="currentColor"
                                class="text-xl transition-colors duration-200 hover:text-mobevpop-600 dark:hover:text-white"
                                viewBox="-271 273 256 256" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M-64.5,273h-157c-27.3,0-49.5,22.2-49.5,49.5v52.3v104.8c0,27.3,22.2,49.5,49.5,49.5h157c27.3,0,49.5-22.2,49.5-49.5V374.7 v-52.3C-15.1,295.2-37.3,273-64.5,273z M-50.3,302.5h5.7v5.6v37.8l-43.3,0.1l-0.1-43.4L-50.3,302.5z M-179.6,374.7 c8.2-11.3,21.5-18.8,36.5-18.8s28.3,7.4,36.5,18.8c5.4,7.4,8.5,16.5,8.5,26.3c0,24.8-20.2,45.1-45.1,45.1s-44.9-20.3-44.9-45.1 C-188.1,391.2-184.9,382.1-179.6,374.7z M-40,479.5C-40,493-51,504-64.5,504h-157c-13.5,0-24.5-11-24.5-24.5V374.7h38.2 c-3.3,8.1-5.2,17-5.2,26.3c0,38.6,31.4,70,70,70c38.6,0,70-31.4,70-70c0-9.3-1.9-18.2-5.2-26.3H-40V479.5z">

                                </path>
                            </svg>

                        </a>

                        <a href="mailto:jorge.grilo@mobev.pt" target="_blank"
                            class="text-gray-400 transition-colors duration-200 hover:text-mobevpop-600 dark:hover:text-white p-8">

                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="40"
                                width="40" fill="currentColor" id="_x32_" viewBox="0 0 512 512">
                                <g>
                                    <polygon points="512,295.199 445.92,226.559 512,169.6  " />
                                    <polygon points="66.16,226.559 0,295.279 0,169.6  " />
                                    <path
                                        d="M512,357.6v63.199c0,15.281-12.4,27.682-27.68,27.682H27.68c-15.281,0-27.68-12.4-27.68-27.682V357.6   l98.959-102.721L212,352.238c11.76,10.082,27.359,15.682,44,15.682c16.641,0,32.32-5.6,44.08-15.682l112.959-97.359L512,357.6z" />
                                    <path
                                        d="M512,91.119v27.68l-241.442,208c-7.76,6.72-21.359,6.72-29.119,0L0,118.799v-27.68   c0-15.279,12.398-27.6,27.68-27.6H484.32C499.6,63.519,512,75.84,512,91.119z" />
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>

                <!--CONTACTOS-->
                <div>

                    <h2 class="text-1xl font-bold tracking-tight text-mobevpop-600 sm:text-1xl astro-font">Contactos</h2>
                    <div class="text-gray-400 hover:text-mobevpop-600 dark:text-gray-300 dark:hover:text-white 
                        transition-colors duration-200 p-2">
                        <a class="inline-block mr-2 align-middle">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="20"
                                width="20" fill="currentColor" id="_x32_" viewBox="0 0 512 512">
                                <g>
                                    <polygon points="512,295.199 445.92,226.559 512,169.6  " />
                                    <polygon points="66.16,226.559 0,295.279 0,169.6  " />
                                    <path
                                        d="M512,357.6v63.199c0,15.281-12.4,27.682-27.68,27.682H27.68c-15.281,0-27.68-12.4-27.68-27.682V357.6   l98.959-102.721L212,352.238c11.76,10.082,27.359,15.682,44,15.682c16.641,0,32.32-5.6,44.08-15.682l112.959-97.359L512,357.6z" />
                                    <path
                                        d="M512,91.119v27.68l-241.442,208c-7.76,6.72-21.359,6.72-29.119,0L0,118.799v-27.68   c0-15.279,12.398-27.6,27.68-27.6H484.32C499.6,63.519,512,75.84,512,91.119z" />
                                </g>
                            </svg>
                        </a>
                        <a href="mailto:jorge.grilo@mobev.pt">Mobev</a>
                    </div>
                    <div class="text-gray-400 hover:text-mobevpop-600 dark:text-gray-300 dark:hover:text-white 
                        transition-colors duration-200 p-2">
                        <a class="inline-block mr-2 align-middle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                fill="currentColor">
                                <path
                                    d="M15.5562 14.5477L15.1007 15.0272C15.1007 15.0272 14.0181 16.167 11.0631 13.0559C8.10812 9.94484 9.1907 8.80507 9.1907 8.80507L9.47752 8.50311C10.1841 7.75924 10.2507 6.56497 9.63424 5.6931L8.37326 3.90961C7.61028 2.8305 6.13596 2.68795 5.26145 3.60864L3.69185 5.26114C3.25823 5.71766 2.96765 6.30945 3.00289 6.96594C3.09304 8.64546 3.81071 12.259 7.81536 16.4752C12.0621 20.9462 16.0468 21.1239 17.6763 20.9631C18.1917 20.9122 18.6399 20.6343 19.0011 20.254L20.4217 18.7584C21.3806 17.7489 21.1102 16.0182 19.8833 15.312L17.9728 14.2123C17.1672 13.7486 16.1858 13.8848 15.5562 14.5477Z" />
                                <path
                                    d="M13.2595 1.87983C13.3257 1.47094 13.7122 1.19357 14.1211 1.25976C14.1464 1.26461 14.2279 1.27983 14.2705 1.28933C14.3559 1.30834 14.4749 1.33759 14.6233 1.38082C14.9201 1.46726 15.3347 1.60967 15.8323 1.8378C16.8286 2.29456 18.1544 3.09356 19.5302 4.46936C20.906 5.84516 21.705 7.17097 22.1617 8.16725C22.3899 8.66487 22.5323 9.07947 22.6187 9.37625C22.6619 9.52466 22.6912 9.64369 22.7102 9.72901C22.7197 9.77168 22.7267 9.80594 22.7315 9.83125L22.7373 9.86245C22.8034 10.2713 22.5286 10.6739 22.1197 10.7401C21.712 10.8061 21.3279 10.53 21.2601 10.1231C21.258 10.1121 21.2522 10.0828 21.2461 10.0551C21.2337 9.9997 21.2124 9.91188 21.1786 9.79572C21.1109 9.56339 20.9934 9.21806 20.7982 8.79238C20.4084 7.94207 19.7074 6.76789 18.4695 5.53002C17.2317 4.29216 16.0575 3.59117 15.2072 3.20134C14.7815 3.00618 14.4362 2.88865 14.2038 2.82097C14.0877 2.78714 13.9417 2.75363 13.8863 2.7413C13.4793 2.67347 13.1935 2.28755 13.2595 1.87983Z" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M13.4857 5.3293C13.5995 4.93102 14.0146 4.7004 14.4129 4.81419L14.2069 5.53534C14.4129 4.81419 14.4129 4.81419 14.4129 4.81419L14.4144 4.81461L14.4159 4.81505L14.4192 4.81602L14.427 4.81834L14.4468 4.8245C14.4618 4.82932 14.4807 4.8356 14.5031 4.84357C14.548 4.85951 14.6074 4.88217 14.6802 4.91337C14.8259 4.97581 15.0249 5.07223 15.2695 5.21694C15.7589 5.50662 16.4271 5.9878 17.2121 6.77277C17.9971 7.55775 18.4782 8.22593 18.7679 8.7154C18.9126 8.95991 19.009 9.15897 19.0715 9.30466C19.1027 9.37746 19.1254 9.43682 19.1413 9.48173C19.1493 9.50418 19.1555 9.52301 19.1604 9.53809L19.1665 9.55788L19.1688 9.56563L19.1698 9.56896L19.1702 9.5705C19.1702 9.5705 19.1707 9.57194 18.4495 9.77798L19.1707 9.57194C19.2845 9.97021 19.0538 10.3853 18.6556 10.4991C18.2607 10.6119 17.8492 10.3862 17.7313 9.99413L17.7276 9.98335C17.7223 9.96832 17.7113 9.93874 17.6928 9.89554C17.6558 9.8092 17.5887 9.66797 17.4771 9.47938C17.2541 9.10264 16.8514 8.53339 16.1514 7.83343C15.4515 7.13348 14.8822 6.73078 14.5055 6.50781C14.3169 6.39619 14.1757 6.32909 14.0893 6.29209C14.0461 6.27358 14.0165 6.26254 14.0015 6.25721L13.9907 6.25352C13.5987 6.13564 13.3729 5.72419 13.4857 5.3293Z" />
                            </svg></a>
                        <a href="tel:+351938848300">938 848 300</a>
                    </div>
                    <div class="text-gray-400 hover:text-mobevpop-600 dark:text-gray-300 dark:hover:text-white 
                        transition-colors duration-200 p-2">
                        <a class="inline-block mr-2 align-middle">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0"
                                id="Layer_1" width="20" height="20" viewBox="0 0 64 64" fill="currentColor">
                                <path
                                    d="M32,0C18.746,0,8,10.746,8,24c0,5.219,1.711,10.008,4.555,13.93c0.051,0.094,0.059,0.199,0.117,0.289l16,24  C29.414,63.332,30.664,64,32,64s2.586-0.668,3.328-1.781l16-24c0.059-0.09,0.066-0.195,0.117-0.289C54.289,34.008,56,29.219,56,24  C56,10.746,45.254,0,32,0z M32,32c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S36.418,32,32,32z" />
                            </svg></a>
                        <a href="https://maps.app.goo.gl/233uuFTKjPNZ2wqS7" target="_blank">Location</a>
                    </div>

                </div>
            </div>


        </div>

    </footer>
</template>
  

<script>
import emailjs from '@emailjs/browser';
import PriceCard from './components/PriceCard.vue'
import { useToast } from "vue-toastification";

export default {

    name: 'App',
    components: {
        PriceCard
    },

    data() {
        return {
            message: ""

        }
    },
    setup() {
        emailjs.init('SVgvTl2y9gKbaAsEE');
    },
    methods: {
        sendEmail(e) {
            const toast = useToast();
            console.log(e.target);
            emailjs.sendForm('service_kt9q01q', 'template_8vwz3o7', e.target, 'SVgvTl2y9gKbaAsEE')
                .then((result) => {
                    console.log('SUCCESS!', result.text);

                    // Use it!
                    toast.success("Mensagem enviada com sucesso! Obrigado");
                }, (error) => {
                    console.log('FAILED...', error.text);
                    toast.error("Ocorreu um erro no envio da mensagem. Tente novamente mais tarde ou outro meio de contacto");
                });
            this.$refs.contactForm.reset();
        },
        contact(pre_message) {
            //alert(pre_message);
            this.message = pre_message;
        },

    },

}
</script>


    


<style scoped>
body,
html {
    font-size: 16pt;
}

.main-header {
    background-image: url(assets/images/electricStation.png);
    background-position: center;

    height: 40rem;
}

.astro-font {
    /*font-family: "Astro Futuristic Font Regular";*/
    font-family: "REZ Regular";
}

h1 {
    font-size: 3rem;
}

h2,
h3 {
    letter-spacing: 2px;
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem 0;
}

.why-us img {
    height: 3rem;
    width: 3rem;
}

.partners {
    background-color: bg-mobevpop-600;

}

.partners h2 {
    color: #FFF;
}

.partners img {
    height: 5rem;
    max-width: auto;
    filter: grayscale();
    transition: ease-in 2s;
}

.partners img:hover {
    filter: none;
    transition: ease-in .3s;
}

.image-alone {
    position: absolute;

    right: 3vw;
    top: -15vw;
}

.image-alone.image-left {
    left: 3vw;
    bottom: 0;
}

.image-alone img {
    height: 40vw;
    width: auto;
}

.image-small {
    width: 10rem;
    display: inline-block;
}
</style>
