import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './../HomePage.vue'



const router = createRouter({
    history: createWebHistory(),
    routes: [
       /* {path: '/', redirect: '/home'},
        {path: '/home', component: HomePage, name:'home'},*/
        {path: '/', component: HomePage, name:'home'}
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
          return {
            el: to.hash,
            behavior: 'smooth',
            from,
            savedPosition
          }
        }
      }
});


export default router