import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'
import './assets/scss/style.scss';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
    position: "bottom-center",
};

const app = createApp(App);
app.use(Toast, options);
app.use(router);
app.mount('#app');

